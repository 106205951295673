// Using require since this file is also called from gatsby-config.js
const chroma = require('chroma-js');

function defaultPalette() {
    const base = '#1c61a3';

    return {
        primary: chroma(base).hex(),
        gray: chroma(base)
            .desaturate(2)
            .brighten(2)
            .hex(),
        lightGray: chroma(base)
            .desaturate(2)
            .brighten(3)
            .hex(),
        darkGray: chroma(base)
            .desaturate(2)
            .darken(2)
            .hex(),
        bright: chroma(base)
            .brighten(4)
            .hex(),
        dark: chroma(base)
            .darken(3)
            .hex(),
        secondary: chroma(base)
            .set('hsl.h', chroma(base).get('hsl.h') + 90)
            .desaturate(2)
            .darken(1)
            .hex(),
    };
}

exports.palette = defaultPalette();
