import Typography from 'typography';
import theme from 'typography-theme-irving';
import 'typeface-exo';
import 'typeface-yrsa';
import 'typeface-pt-mono';
import { palette } from './palette';
import { MOBILE_MEDIA_QUERY } from 'typography-breakpoint-constants';

theme.baseFontSize = '24px';
theme.googleFonts = [];
theme.overrideThemeStyles = ({ scale, rhythm }, options) => ({
    'h1, h2, h3, h4, h5, h6': { clear: 'both' },

    'h1, h2, h1 > a': {
        color: palette.primary,
    },
    'h3, h4, h5, h6': { color: palette.secondary },

    blockquote: {
        borderWidth: `5px`,
        borderLeftColor: palette.secondary,
        color: palette.secondary,
        backgroundColor: palette.lightGray,
        padding: `${rhythm(0.5)}`,
    },

    [MOBILE_MEDIA_QUERY]: {
        html: {
            ...scale(1 / 2),
        },
    },
});

export const typography = new Typography(theme);
export default typography;

export const codeFontFamily = ['PT Mono', 'Consolas', 'Monaco', 'monospace'];
